



























































































































































































































































.framePage-body{
  background: transparent;
  position: relative;
}
.centerBox{
  min-width: 866px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  background: #fff;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0px 2px 18px 0px rgba(0,0,0,0.08);
}
.centerTitle{
  text-align: center;
  font-size: 18px;
  color: #333;
  margin-bottom: 12px;
}
.centerDesc{
  text-align: center;
  font-size: 12px;
  color: #999;
  padding-bottom: 14px;
}
.formTitle{
  margin-top: 16px;
  margin-bottom: 30px;
}
/deep/.el-upload{
  border: 0;
  //margin: 0 auto;
  width: 238px;
  height: 169px;
}
/deep/.el-form-item{
  //margin-left: 30px;
  //margin-right: 60px;
  margin-bottom: 0;
}

/deep/.el-form-item__label{
  text-align: left;
}
/deep/.uploadFormItem{
  //float: left;
  //width: 50%;
  margin: 0 0 10px;
  .el-form-item__label{
    float: none;
    width: 100% !important;
  }
  .el-form-item__content{
    margin-left: 0!important;
  }
  &.is-required:not(.is-no-asterisk)>.el-form-item__label:before{
    content: '';
    margin: 0;
  }
  p{
    line-height: 16px;
    font-size: 12px;
    color: #999;
  }
}
.btn-box{
  text-align: center;
  .el-button{
    margin-top: 30px;
    width: 246px;
    padding-top: 0;
    padding-bottom: 0;
    height: 34px;
    line-height: 34px;
  }
}
.exampleBg{
  background: #FFFDF8;
  padding: 84px 60px 96px;
}
.imageBox{
  width: 240px;
  height: 171px;
}
